<template>
  <v-container>
    <v-card
      class="d-flex justify-space-between mb-6"
      flat
      tile
    >
      <v-card class="pa-2" flat tile style="font-size: 20px; font-weight: bold;">
        {{ $t("message.config-list") }}
      </v-card>
      <v-card class="pa-2" flat tile>
        <v-btn rounded color="primary" @click.prevent="toCreate">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t("message.config-create")}}
        </v-btn>
      </v-card>
    </v-card>
    <!-- data table -->
    <v-data-table
      :headers="headers"
      :items="configList"
      :hide-default-footer="true"
      disable-sort
      class="elavation-1"
    >
      <!-- action button itemlist loop -->
      <template v-slot:[`item.value`]="{ item }">
        <div style="word-break: break-all;">
          {{ item.value }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="secondary" small class="mr-2" @click="toDetail(item.id)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "CofigList",
  data: () => ({}),
  computed: {
    headers() {
      return [
        {
          text: this.$t("message.label-id"),
          align: "start",
          sortable: false,
          value: "id",
          width: "5%",
        },
        { text: this.$t("message.code"), value: "code", width: "13%" },
        { text: this.$t("message.label-description"), value: "description", width: "13%" },
        { text: this.$t("message.value"), value: "value", width: "30%" },
        { text: this.$t("message.label-action"), value: "actions", width: "7%" },
      ];
    },
    configList() {
      return this.$store.state.config_module.configList;
    },
  },

  created() {
    this.dispatchConfigList();
  },

  methods: {
    toCreate(){
      this.$router.push(`/config/create`)
    },
    dispatchConfigList() {
      this.$store.dispatch("config_module/fetchConfigList");
    },
    toDetail(itemId) {
      this.$store.dispatch("config_module/fetchConfigDetail", { id: itemId });
      this.$router.push(`/config/${itemId}`);
    },
  },
};
</script>

<style>
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
</style>
